import axios from 'axios';
import { fcoUrl } from '../../fcoModules/utilities';

// PRIVATE VARS/FUNCTIONS

// PUBLIC PROPERTIES

// PUBLIC METHODS
export const getCurrentShopNotes = () => axios.get(fcoUrl('/shop/notes'));

export const saveShopNote = (text, { requestId, dateTime = Date.now(), internal = true } = {}) =>
    axios.post(fcoUrl('/shop/notes'), { text, requestId, dateTime, internal });

export const getShopNotesById = (shopId, requestFlag) => {
    const urlStr = requestFlag && shopId !== '' ? `/admin/shopRequest/rest/${shopId}/notes` : `/shop/notes/${shopId}`;
    return axios.get(fcoUrl(`${urlStr}`));
};
export const saveShopNoteById = (shopId, text, requestFlag, { createDateTime = Date.now(), internal = true } = {}) => {
    if (requestFlag && shopId !== '') {
        return axios.post(fcoUrl(`/admin/shopRequest/rest/notes`), { text, requestId: shopId, createDateTime, internal });
    }
    return axios.post(fcoUrl(`/shop/notes/${shopId}`), { text, createDateTime, internal });
};

export const addNewShop = (formData) => axios.post(fcoUrl(`/admin/shopRequest/rest/`), formData);
export const saveShopPreferencesById = (shopId, formData, requestFlag) => {
    const urlStr = requestFlag ? '/admin/shopRequest/rest' : '/shop/savePreferences';
    return axios.put(fcoUrl(`${urlStr}/${shopId}`), formData);
};
export const getShopDetailsById = (shopId = '', requestFlag) => {
    const urlStr = requestFlag ? '/admin/shopRequest/rest' : '/shop/shopDetails';
    return axios.get(fcoUrl(`${urlStr}/${shopId}`));
};

export const selectShop = (id) => axios.post(fcoUrl('/shop/shopprocess', { params: { key: id } }));

export const getEmployees = (shopId, search, page, sort) => axios.get(fcoUrl(`/shop/employeesList/${shopId}`), { params: { search, page, sort } });

export const getShopsByUserId = (userId) => axios.get(fcoUrl(`/shop/user/${userId}`));

export const getCurrentUserShops = () => axios.get(fcoUrl('/current/user/shops'));
