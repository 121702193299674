// A mixin providing methods for signals tracking
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { mapState } from 'vuex';
import { SignalsEventTypes } from '../../../common/constants/ordering';

const DEFAULT_SEARCH_UUID = 'xxxx';

const sessionKeys = {
    SEARCH_UUID: 'signalsSearchUuid',
    SESSION_UUID: 'signalSessionUuid',
    SIGNALS_CART: 'signalsCart',
};

const productDefaults = {
    PRODUCT_SOURCE: 'SEARCH',
    PRICE_NAME: 'price',
};

export default {
    props: {
        trackSignals: {
            type: Boolean,
            default: false,
        },
        useDefaultSearchUuid: {
            type: Boolean,
            default: true,
        },
        currentPosition: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState(['requests', 'user', 'currentShop', 'currentShop']),
    },
    methods: {
        $_signalsMixin_setSearchUuid(searchUuid) {
            sessionStorage.setItem(sessionKeys.SEARCH_UUID, searchUuid);
        },
        $_signalsMixin_setSessionUuid() {
            sessionStorage.setItem(sessionKeys.SESSION_UUID, uuidv4());
        },
        $_signalsMixin_setSessionCart({ line, itemNumber, resultPosition, quantity, value }, worksheetId) {
            const curCart = this.$_signalsMixin_getSessionCart();
            const finalProductContext = {
                line,
                itemNumber,
                resultPosition,
                productSource: productDefaults.PRODUCT_SOURCE,
                quantity,
                listPrice: {
                    name: productDefaults.PRICE_NAME,
                    value,
                },
                session: this.$_signalsMixin_getSessionContext(),
            };

            if (curCart.hasOwnProperty(worksheetId)) {
                curCart[worksheetId].push(finalProductContext);
            } else {
                curCart[worksheetId] = [finalProductContext];
            }

            sessionStorage.setItem(sessionKeys.SIGNALS_CART, JSON.stringify(curCart));
        },
        $_signalsMixin_getSessionCart() {
            return JSON.parse(sessionStorage.getItem(sessionKeys.SIGNALS_CART)) || {};
        },
        $_signalsMixin_getSearchUuid() {
            if (this.useDefaultSearchUuid) return DEFAULT_SEARCH_UUID;
            return sessionStorage.getItem(sessionKeys.SEARCH_UUID) || DEFAULT_SEARCH_UUID;
        },
        $_signalsMixin_getSessionUuid() {
            return sessionStorage.getItem(sessionKeys.SESSION_UUID);
        },
        $_signalsMixin_getSessionContext() {
            return {
                searchUuid: this.$_signalsMixin_getSearchUuid(),
                sessionId: this.$_signalsMixin_getSessionUuid(),
                context: {
                    accountNumber: this.currentShop?.accountNumber,
                    userId: this.user.userId,
                    endPointUrl: window.location.href,
                },
            };
        },
        $_signalsMixin_removeWorksheetCart(worksheetId) {
            const signalsCart = this.$_signalsMixin_getSessionCart();
            if (signalsCart.hasOwnProperty(worksheetId)) {
                delete signalsCart[worksheetId];
                sessionStorage.setItem(sessionKeys.SIGNALS_CART, JSON.stringify(signalsCart));
            }
        },
        $_signalsMixin_trackAutoCompleteSelection({ selectedSuggestion, position, resultCount }) {
            try {
                axios.post(this.fcoUrl('/signals/suggestions'), {
                    store: this.currentShop?.homeStore.storeId,
                    position,
                    resultCount,
                    selectedSuggestion,
                    session: this.$_signalsMixin_getSessionContext(),
                });
            } catch {
                // ignore signal tracking failures
            }
        },
        $_signalsMixin_trackSignalsEvent(eventType, { line, itemNumber, resultPosition, quantity, value }, productList = []) {
            const finalProductData =
                eventType === SignalsEventTypes.PURCHASE
                    ? productList
                    : [
                          {
                              line,
                              itemNumber,
                              resultPosition,
                              productSource: productDefaults.PRODUCT_SOURCE,
                              quantity,
                              listPrice: {
                                  name: productDefaults.PRICE_NAME,
                                  value,
                              },
                              session: this.$_signalsMixin_getSessionContext(),
                          },
                      ];
            try {
                axios.post(this.fcoUrl('/signals/products'), {
                    store: this.currentShop?.homeStore.storeId,
                    type: eventType,
                    productTrackingContexts: finalProductData,
                });
            } catch {
                // ignore signal tracking failures
            }
        },
    },
    mounted() {
        if (this.trackSignals) {
            this.$store.dispatch('requestIfIdle', ['getUser', 'getCurrentShop']);
        }
        // make sure anytime signals tracking mixin is utilized that a session id is set
        if (!this.$_signalsMixin_getSessionUuid()) {
            this.$_signalsMixin_setSessionUuid();
        }
    },
};
