/* eslint no-shadow: ['error', { 'allow': ['state', 'getters'] }] */
import axios from 'axios';
import { fcoUrl } from '@/fcoModules/utilities';
import { VEHICLE_ATTRIBUTE_IDS } from '@/common/constants/vehicle';
import { createRequestStatus, requestStatus, trackRequestStatus, updateRequestStatus } from '../request-status';
import { StorageKey, getStorageItem, hasStorageItem, setStorageItem } from '../store-utils';

export const state = {
    forceUrl: null,
    saveLookupBeforeRedirect: false,
    partsToSaveBeforeRedirect: null,
    partTypePendingForceCar: false,
    height: 0,
    isLoadingData: false,
    isOpen: false,
    isOpenDefault: false,
    isRecentVehiclesModalShown: false,
    isRecentVehiclesDataLoaded: false,
    isVehicleInfoShown: false,
    isYearSelectorOpen: false,
    isEditSelectionOpen: false,
    recentVehicles: [],
    errorLoadingData: false,
    preventNavigationOnVehicleUpdate: false,

    // This is a default mapping of all the properties used for VS vue components currently.
    currentVehicle: {
        answeredAttributes: [],
        id: null,
        make: '',
        model: '',
        shopVehicleDescriptor: '',
        vehicleId: null,
        vin: '',
        year: null,
        shopCustomerId: null,
        shopCustomerFirst: '',
        shopCustomerLast: '',
    },
    vsData: {
        lastState: '',
        states: [],
        years: [],
    },
    requests: {
        getCurrentVehicle: createRequestStatus(),
        getVehicleSelectorData: createRequestStatus(),
        getAnsweredAttributesTranslated: createRequestStatus(),
    },
};

export const mutations = {
    updateRequestStatus,
    setRecentVehicles(state, recentVehicles) {
        state.recentVehicles = recentVehicles;
    },
    setIsRecentVehiclesModalShown(state, value) {
        state.isRecentVehiclesModalShown = value;
    },
    setIsVehicleInfoShown(state, value) {
        state.isVehicleInfoShown = value;
    },
    setCurrentVehicle(state, currentVehicle) {
        // some components commit to this mutation directly so need to be safe against invalid vehicles being saved
        state.currentVehicle = currentVehicle?.id && currentVehicle?.vehicleId ? currentVehicle : null;

        // required for FE data persistence
        setStorageItem(StorageKey.CURRENT_VEHICLE, state.currentVehicle);
    },
    setAttributes(state, attributes) {
        state.currentVehicle.answeredAttributes = attributes;
        setStorageItem(StorageKey.CURRENT_VEHICLE, state.currentVehicle);
    },
    setVehicleSelectorData(state, data) {
        state.vsData = data;
    },
    resetVehicleData(state) {
        state.currentVehicle = null;

        // required for FE data persistence
        setStorageItem(StorageKey.CURRENT_VEHICLE, null);
    },
    addAttribute(state, { attributeId, attributeDescription, valueId, valueDescription, displayDescription }) {
        const index = state.currentVehicle.answeredAttributes.findIndex((attribute) => attribute.attributeId === attributeId);

        // if vehicle already has this attribute type then replace it, otherwise just add it.
        if (index !== -1) {
            state.currentVehicle.answeredAttributes.splice(index, 1, {
                attributeId,
                attributeDescription,
                valueId,
                valueDescription,
                displayDescription,
            });
        } else {
            state.currentVehicle.answeredAttributes.push({ attributeId, attributeDescription, valueId, valueDescription, displayDescription });
        }

        // required for FE data persistence
        setStorageItem(StorageKey.CURRENT_VEHICLE, state.currentVehicle);
    },
    removeAttributes(state, ids) {
        const { answeredAttributes } = state.currentVehicle;
        state.currentVehicle.answeredAttributes = answeredAttributes.filter((attr) => !ids.includes(Number(attr.attributeId)));

        // required for FE data persistence
        setStorageItem(StorageKey.CURRENT_VEHICLE, state.currentVehicle);
    },
    setVsProp(state, { prop, value }) {
        state[prop] = value;
    },
    setShopCustomer(state, { firstName, lastName, id }) {
        state.currentVehicle.shopCustomerId = id;
        state.currentVehicle.shopCustomerFirst = firstName;
        state.currentVehicle.shopCustomerLast = lastName;
    },
};

export const actions = {
    async getCurrentVehicle({ commit }, forceNewRequest = false) {
        // check hasOwnProperty instead of value check because we save a value of null when no vehicle is selected
        // this allows for us to not make more requests each page load once we know a vehicle is not selected
        if (hasStorageItem(StorageKey.CURRENT_VEHICLE) && !forceNewRequest) {
            const curVehicle = getStorageItem(StorageKey.CURRENT_VEHICLE);
            commit('setCurrentVehicle', curVehicle);

            // I think I should leave this to satisfy request status, we might want to keep the request in case user
            // clears out local cache but still has a vehicle in session
            commit('updateRequestStatus', {
                name: 'getCurrentVehicle',
                status: requestStatus.SUCCESS,
                statusCode: 200,
                error: null,
            });
        } else {
            const { data: currentVehicle } = await trackRequestStatus(commit, 'getCurrentVehicle', axios.get(fcoUrl('/current/vehicle')));
            commit('setCurrentVehicle', currentVehicle.id ? currentVehicle : null);
        }
    },
    async getVehicleSelectorData({ commit }) {
        const { data: vsData } = await trackRequestStatus(commit, 'getVehicleSelectorData', axios.get(fcoUrl('/vehicle/select/vehicleSelectorData')));
        commit('setVehicleSelectorData', vsData);
    },
    async getAnsweredAttributesTranslated({ commit }, { worksheetId, worksheetVehicleId }) {
        const { data: vehicleTranslated } = await trackRequestStatus(
            commit,
            'getAnsweredAttributesTranslated',
            axios.get(fcoUrl(`/worksheet/rest/${worksheetId}/vehicle/${worksheetVehicleId}/translate`))
        );
        commit('setAttributes', vehicleTranslated.answeredAttributes);
    },
    async clearCurrentVehicle({ state, commit }) {
        // when we clear vehicle we really want to start a new worksheet, this endpoint removes vehicle and quote items
        // and starts a new worksheet.
        await axios.post(fcoUrl('/worksheet/rest/clear'));
        commit('resetVehicleData');
        if (!state.isOpenDefault) {
            commit('setVsProp', { prop: 'isOpen', value: false });
        }
    },
    async getRecentVehicles({ commit }) {
        const { data: recentVehicles } = await axios.get(fcoUrl('/recent/vehicles'));
        commit('setRecentVehicles', recentVehicles);
        commit('setVsProp', { prop: 'isRecentVehiclesDataLoaded', value: true });
    },
    async deleteRecentVehicle({ commit, state }, vehicleId) {
        const url = fcoUrl(`/recent/deleteRecentVehicle.json?recentVehicleId=${vehicleId}`);

        await axios.delete(url);
        const recentVehicles = state.recentVehicles.filter((vehicle) => vehicle.id !== vehicleId);

        commit('setRecentVehicles', recentVehicles);
    },
    async selectEngineSubmodel({ state, commit }, { engineId, submodelId }) {
        const { data } = await axios.post(fcoUrl('/vehicle/select/v2/selectAttributes'), { engineId, submodelId });
        commit('setCurrentVehicle', data.vehicle);

        if (!state.isOpenDefault) {
            commit('setVsProp', { prop: 'isOpen', value: false });
        }
    },
    setVsProp({ commit }, data) {
        commit('setVsProp', data);
    },
    autoExpand({ commit }, value = true) {
        if (value) {
            commit('setVsProp', { prop: 'isOpen', value });
        }
        commit('setVsProp', { prop: 'isOpenDefault', value });
    },
    openYearSelect({ commit }) {
        commit('setVsProp', { prop: 'isOpen', value: true });
        commit('setVsProp', { prop: 'isYearSelectorOpen', value: true });
    },
    openEditOptions({ commit }) {
        commit('setVsProp', { prop: 'isEditSelectionOpen', value: true });
    },
    forceCar({ commit, state }, newUrl = state.isSPA ? window.location.hash : window.location.href) {
        commit('setVsProp', { prop: 'forceUrl', value: newUrl });
        commit('setVsProp', { prop: 'isOpen', value: true });
    },
};

export const getters = {
    shopCustomer: ({ currentVehicle }) => {
        if (!currentVehicle?.shopCustomerId) return null;
        return {
            id: currentVehicle.shopCustomerId,
            firstName: currentVehicle.shopCustomerFirst,
            lastName: currentVehicle.shopCustomerLast,
        };
    },
    vehicleEngine: ({ currentVehicle }) => currentVehicle.answeredAttributes.find((attr) => attr.attributeId === VEHICLE_ATTRIBUTE_IDS.ENGINE),
    vehicleSubmodel: ({ currentVehicle }) => currentVehicle.answeredAttributes.find((attr) => attr.attributeId === VEHICLE_ATTRIBUTE_IDS.SUBMODEL),
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
